@use '@angular/material' as mat;
@use 'sass:map';

@mixin iot-platform-feature-variable-chart-theme($theme) {
  $accent: mat.m2-get-color-from-palette(map.get($theme, accent), default);
  $accent-color: mat.m2-get-color-from-palette(map.get($theme, accent), default-contrast);
  $primary: mat.m2-get-color-from-palette(map.get($theme, primary), default);
  $primary-color: mat.m2-get-color-from-palette(map.get($theme, primary), default-contrast);

  .variable-chart-selector-button {
    color: $accent;
    background-color: $accent-color;
    margin: 0 4px;

    &.selected {
      color: $accent-color;
      background-color: $accent;
    }
  }

  .variable-chart-scale-button {
    color: $accent;
    background-color: $accent-color;

    &.selected {
      color: $accent-color;
      background-color: $accent;
    }
  }

  .variable-chart-form {
    .variable-chart-form-info-icon {
      color: $primary-color;
    }
  }

  .variable-chart-export-button {
    color: $primary-color;
  }

  .variable-chart-export {
    color: $primary-color;
  }

  .variable-chart-period {
    color: #b0b0b0;
  }

  .variable-chart-period-content {
    &.date {
      color: $primary-color;
    }
  }

  .variable-chart-table {
    .mat-mdc-header-row {
      background-color: #fff;
    }

    .mat-mdc-header-cell {
      color: $primary-color;
    }

    .mat-mdc-cell {
      color: $primary-color;
    }
  }

  .highcharts-data-table tr:hover {
    background: $primary;
  }

  .highcharts-data-table thead tr,
  .highcharts-data-table tr:nth-child(even) {
    background: #f8f8f8;
  }
  .highcharts-data-table tr:nth-child(odd) {
    background: #fff;
  }

  .highcharts-data-table {
    color: $primary-color;
  }

  .variables-closed {
    .hide {
      display: none !important;
    }

    .highcharts-data-table {
      background-color: #f8f8f8;
      overflow: auto;
      font-size: 12px;
      width: 100%;
    }
  }

  .highcharts-data-table table {
    border-collapse: collapse;
    border: 1px solid #ebebeb;
    margin: 0;
    text-align: center;
    width: 100%;

    tr:first-child th:first-child {
      min-width: 80px;
    }

    thead th {
      cursor: pointer;

      &:hover {
        background-color: $primary;
      }

      &.highcharts-sort-descending::after {
        content: ' ↓';
      }

      &.highcharts-sort-ascending::after {
        content: ' ↑';
      }
    }
  }

  .highcharts-data-table caption {
    display: none;
  }

  .highcharts-data-table th {
    font-weight: 600;
    padding: 0.5em;
  }

  .highcharts-data-table td,
  .highcharts-data-table th,
  .highcharts-data-table caption {
    padding: 0.5em;
  }

  .variable-chart-side-nav-container button,
  mat-slide-toggle,
  .variable-chart-add-variables-button .mat-icon,
  .variable-chart-dates-filter input {
    color: $primary-color;
  }
}
